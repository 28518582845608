<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-info"
              >
                <feather-icon
                  size="24"
                  icon="InboxIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ dataItem.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Stok Kartı
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col>
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-warning"
              >
                <feather-icon
                  size="24"
                  icon="CheckCircleIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ dataItem.amount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Toplam {{ dataItem.units }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col>
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="DownloadIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ dataItem.entry_amounts | toNumber }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Toplam Giriş {{ dataItem.units }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col>
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-danger"
              >
                <feather-icon
                  size="24"
                  icon="UploadIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ dataItem.output_amounts | toNumber }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Toplam Çıkış {{ dataItem.units }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Stok Kayıtları</b-card-title>
        <b-button
          variant="primary"
          :to="'/app/stocks/addlines/' + $route.params.id"
        >
          <feather-icon icon="PlusIcon" />
          Oluştur
        </b-button>
      </b-card-header>
      <b-table
        v-if="dataList.length > 0 "
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(created)="data">
          {{ moment(data.item.created).format('DD.MM.YYYY') }}
          <div>
            <small class="text-warning">{{ data.item.username }}</small>
          </div>
        </template>
        <template #cell(price)="data">
          {{ data.item.price |toNumber }}
          <span>
            <small class="text-primary">TL</small>
          </span>
        </template>
        <template #cell(entry_amount)="data">
          <span v-if="data.item.entry_amount">{{ data.item.entry_amount | toNumber }}
          </span>
          <small
            v-if="dataItem.units && data.item.entry_amount"
            class="text-primary"
          >{{ dataItem.units }}</small>
          <div v-if="data.item.modified && data.item.entry_amount">
            <small class="text-warning">Güncelleme : {{ moment(data.item.modified).format('DD.MM.YYYY') }}</small>
          </div>
          <div v-if="data.item.notes && data.item.entry_amount">
            <b-badge
              v-b-popover.hover.top="data.item.notes"
              variant="primary"
              title="Güncelleme Notu"
            >
              <feather-icon
                icon="StarIcon"
                class="mr-25"
              />
              <span>Güncelleme Notu</span>
            </b-badge>
          </div>
        </template>
        <template #cell(output_amount)="data">
          <span v-if="data.item.output_amount">{{ data.item.output_amount | toNumber }}
          </span>
          <small
            v-if="dataItem.units && data.item.output_amount"
            class="text-primary"
          >{{ dataItem.units }}</small>
          <div v-if="data.item.modified && data.item.output_amount">
            <small class="text-warning">Güncelleme : {{ moment(data.item.modified).format('DD.MM.YYYY') }}.</small>
          </div>
          <div v-if="data.item.notes && data.item.output_amount">
            <b-badge
              v-b-popover.hover.top="data.item.notes"
              variant="primary"
              title="Güncelleme Notu"
            >
              <feather-icon
                icon="StarIcon"
                class="mr-25"
              />
              <span>Güncelleme Notu</span>
            </b-badge>
          </div>
        </template>
        <template #cell(total_price)="data">
          {{ data.item.total_Price | toNumber }}
          <span>
            <small class="text-primary">TL</small>
          </span>
        </template>
        <template #cell(control)="data">
          <b-button
            v-if="data.item.output_amount"
            variant="flat-secondary"
            class="btn-icon rounded-circle"
            @click="LineEditModal(data.item.id,2)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            v-if="data.item.entry_amount"
            variant="flat-secondary"
            class="btn-icon rounded-circle"
            @click="LineEditModal(data.item.id,1)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </template>
      </b-table>
      <b-alert
        v-else
        show
        variant="info"
        class="mr-2 ml-2"
      >
        <div class="alert-body text-center">
          <div class="lead">
            Stok stok kaydı bulunamadı.
          </div>
          <div class="mt-1">
            <b-button
              variant="info"
              size="sm"
              :to="'/app/stocks/addlines/' + $route.params.id"
            >
              Yeni stok kaydı Oluştur
            </b-button>
          </div>
        </div>
      </b-alert>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
    <b-modal
      v-model="lineEditModal.status"
      :title="lineEditModal.type === 1 ? 'Giriş Miktarını Güncelle' : lineEditModal.type === 2 ? 'Çıkış Miktarını Güncelle': 'Miktar Güncelle'"
      centered
    >
      <p>Güncellemek istediğiniz yeni miktarı giriniz.</p>
      <b-form-group
        label="Fatura Numarası"
        label-for="invoice_number"
      >
        <b-form-input
          id="invoice_number"
          v-model="lineEditModal.invoice_number"
          placeholder="Fatura Numarası"
        />
      </b-form-group>
      <template v-if="lineEditModal.type === 1">
        <b-form-group
          label="Giriş Miktarı"
          label-for="min_amount"
        >
          <b-input-group :append="dataItem.units">
            <cleave
              id="entry_amount"
              v-model="lineEditModal.entry_amount"
              class="form-control"
              :raw="true"
              :options="options"
              placeholder="Giriniz"
            />
          </b-input-group>
        </b-form-group>
      </template>
      <template v-if="lineEditModal.type === 2">
        <b-form-group
          label="Çıkış Miktarı"
          label-for="min_amount"
        >
          <b-input-group :append="dataItem.units">
            <cleave
              id="output_amount"
              v-model="lineEditModal.output_amount"
              class="form-control"
              :raw="true"
              :options="options"
              placeholder="Giriniz"
            />
          </b-input-group>
        </b-form-group>
      </template>
      <b-form-group
        label="Birim Fiyat"
        label-for="price"
      >
        <b-input-group append="TL">
          <cleave
            id="price"
            v-model="lineEditModal.price"
            class="form-control"
            :raw="true"
            :options="options"
            placeholder="Giriniz"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="Güncelleme Notu"
        label-for="notes"
      >
        <b-form-textarea
          id="notes"
          v-model="lineEditModal.notes"
          placeholder="Not"
        />
      </b-form-group>
      <template #modal-footer>
        <b-button @click="lineEditModal.status = false">
          Kapat
        </b-button>
        <b-button
          variant="success"
          :disabled="!lineEditModal.notes || !lineEditModal.price"
          @click="editLines()"
        >
          Güncelle
        </b-button>
      </template>

    </b-modal>
  </div>
</template>
<script>
import {
  BAlert,
  BButton,
  BCardFooter,
  BPagination,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCardText,
  BInputGroup, BFormGroup, BFormTextarea, BFormInput, BBadge, VBPopover,
} from 'bootstrap-vue'
import moment from 'moment'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ViewStocks',
  components: {
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BInputGroup,
    BCardFooter,
    BButton,
    BTable,
    BPagination,
    BAlert,
    BCardHeader,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCardText,
    Cleave,
    BBadge,
  },
  directives: {
    'b-popover': VBPopover,
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      options: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
      },
      lineEditModal: {
        id: null,
        type: null,
        entry_amount: null,
        output_amount: null,
        invoice_number: null,
        id_stocks: null,
        price: null,
        notes: null,
        status: false,
      },
      fields: [
        {
          key: 'created',
          label: 'Tarih',
        },
        {
          key: 'invoice_number',
          label: 'Fatura Numarası',
        },
        {
          key: 'entry_amount',
          label: 'Giriş Miktarı',
        },
        {
          key: 'output_amount',
          label: 'Çıkış Miktarı',
        },
        {
          key: 'price',
          label: 'Birim Fiyat',
          thClass: 'text-nowrap',
        },
        {
          key: 'total_price',
          label: 'Toplam Fiyat',
          thClass: 'text-nowrap',

        },
        {
          key: 'control',
          label: '',
          thClass: 'text-nowrap width-50',
        },
      ],
      dataQuery: {
        where: {
          'stock_lines.id_stocks': this.$route.params.id,
        },
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    moment() {
      return moment
    },
    dataItem() {
      return this.$store.getters['stocks/dataItem']
    },
    dataList() {
      return this.$store.getters['stockLines/dataList']
    },
    dataCounts() {
      return this.$store.getters['stockLines/dataCounts']
    },
    lineData() {
      return this.$store.getters['stockLines/dataItem']
    },
    saveLinesStatus() {
      return this.$store.getters['stockLines/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getData()
    },
    saveLinesStatus(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.lineEditModal.status = false
        this.lineEditModal.id = null
        this.lineEditModal.entry_amount = null
        this.lineEditModal.output_amount = null
        this.lineEditModal.invoice_number = null
        this.lineEditModal.price = null
        this.lineEditModal.notes = null
        this.getLines()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getData()
    this.getLines()
  },
  methods: {
    getData() {
      this.$store.dispatch('stocks/getDataItem', this.$route.params.id)
    },
    getLines() {
      this.$store.dispatch('stockLines/getDataList', this.dataQuery)
    },
    getLine(index) {
      this.$store.dispatch('stockLines/getDataItem', index)
        .then(response => {
          this.lineEditModal.id = response.id
          this.lineEditModal.output_amount = response.output_amount
          this.lineEditModal.entry_amount = response.entry_amount
          this.lineEditModal.invoice_number = response.invoice_number
          this.lineEditModal.price = response.price
          this.lineEditModal.notes = response.notes
          this.lineEditModal.id_stocks = response.id_stocks
        })
    },
    LineEditModal(index, type) {
      this.getLine(index)
      this.lineEditModal.type = type
      this.lineEditModal.status = true
    },
    editLines() {
      if (this.lineEditModal.type === 1) {
        this.$store.dispatch('stockLines/saveData', {
          id: this.lineEditModal.id,
          price: this.lineEditModal.price,
          notes: this.lineEditModal.notes,
          entry_amount: this.lineEditModal.entry_amount,
          invoice_number: this.lineEditModal.invoice_number,
          id_stocks: this.lineEditModal.id_stocks,
        })
      } else if (this.lineEditModal.type === 2) {
        this.$store.dispatch('stockLines/saveData', {
          id: this.lineEditModal.id,
          price: this.lineEditModal.price,
          notes: this.lineEditModal.notes,
          output_amount: this.lineEditModal.output_amount,
          invoice_number: this.lineEditModal.invoice_number,
        })
      }
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
